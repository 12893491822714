.formError {
	position: absolute;
	display: block;
	cursor: pointer;
	text-align: left;
	z-index: 990;

	.formErrorContent{
		width: 100%;
		background: $info;
		position:relative;
		color: $white;
		min-width: 175px;
		font-size: $font-size-sm;
		box-shadow: 0 0 3px $black;
		padding: 0.5rem 1rem;
		border-radius: 3px;
		z-index: 991;
	}

	.formErrorArrow{
		width: 15px;
		margin: -2px 0 0 13px;
		position:relative;
		z-index: 996;

		div{
			box-shadow: 0 2px 3px $black;
			font-size: 0px;
			height: 1px;
			background: $info;
			margin: 0 auto;
			line-height: 0;
			font-size: 0;
			display: block;
		}

		.line10{ 
			width: 10px;
			border: none; 
		}

		.line9 {
			width: 7px;
			border: none;
		}

		.line8 {
			width: 8px;
		}

		.line7 {
			width: 7px;
		}

		.line6 {
			width: 6px;
		}

		.line5 {
			width: 5px;
		}

		.line4 {
			width: 4px;
		}

		.line3 {
			width: 3px;
		}

		.line2 {
			width: 2px;
		}

		.line1 {
			width: 1px;
		}

	}

}