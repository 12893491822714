.content{
	padding-bottom:3rem;
}

.inside-title{
    background:$primary;
    text-align:center;
    padding:1rem 0;
    font-size:$font-size-lg;
	margin:0 0 1rem;
	font-weight:$font-weight-bold;
	color:$white;
}

.block-title{
	font-size:$font-size-xl;
	font-weight:$font-weight-bold;
}

.spinner-border{
	width:6rem;
	height:6rem;
}

.page-item{
	margin-left:0.2rem;
}

.breadcrumb-item{
	color:$white;
}

.breadcrumb-item a{
	color:#caa256
}

.account-tabs{
	.nav-item{
		background-color:$white;
		margin-bottom:0.5rem;

		svg{
			font-size:$font-size-lg;
			margin-right:0.4rem;
			color:$info;
		}

		&.active{
			svg{
				color:$white;
			}
		}
	}
}

.header-top {
    .d-flex {
        position: relative;
        z-index: 9999;
    }
}
.espa-banner {
    display: none; 
    position: relative; 
    left: 120px;
    margin-bottom: -45px;

    @media only screen and (max-width: 991px) {
        margin-bottom: 15px;
        text-align: center;
		left: 0px;
		display: block;
    }

    img {
        position: relative;
        z-index: 1000;
    }
}
.espa-banner-right {
    display: block; 
    position: relative; 
    // left: 120px;
    margin-left: 14px;

    @media only screen and (max-width: 991px) {
        margin-bottom: 15px;
        text-align: center;
		left: 0px;
		display: none;
    }

    img {
        position: relative;
        z-index: 1000;
    }
}
.languages {
	margin-left: 0px;
}