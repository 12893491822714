.search{

    &__close{
        position:absolute;
        top:1.3rem;
        right:1.5rem;
        font-size:2rem;
        cursor:pointer;

        &:hover{
            color:$info;
        }
    }

    &__form{
        display:flex;
        align-items: center;
        margin-right:1rem;
    }

    &__input{
        background-color:$gray-200;
        border:0;
        color:$primary;
    }

    &__action{
        border:0;
        background:none;
        color:$primary;
        margin-left:-2rem;
    }

    &__results{
        background-color:$info;
        padding-top:1rem;
        padding-bottom:1rem;
        position:absolute;
        top:6.6rem;
        left:0px;
        right:0px;
        display:none;
        z-index:2000;   
			
        @media only screen and (max-width: 991px) {
            top:3.6rem ;
        }

        ul{
            padding:0;
            margin:0;
            list-style:none;
        }
    }

}