/* ubuntu-300 - latin_greek */
@font-face {
	font-family: 'Ubuntu';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('/Assets/fonts/ubuntu-v14-latin_greek-300.eot'); /* IE9 Compat Modes */
	src: local('Ubuntu Light'), local('Ubuntu-Light'),
		 url('/Assets/fonts/ubuntu-v14-latin_greek-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-300.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-300.woff') format('woff'), /* Modern Browsers */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-300.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-300.svg#Ubuntu') format('svg'); /* Legacy iOS */
  }
  /* ubuntu-regular - latin_greek */
  @font-face {
	font-family: 'Ubuntu';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('/Assets/fonts/ubuntu-v14-latin_greek-regular.eot'); /* IE9 Compat Modes */
	src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
		 url('/Assets/fonts/ubuntu-v14-latin_greek-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-regular.woff') format('woff'), /* Modern Browsers */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-regular.svg#Ubuntu') format('svg'); /* Legacy iOS */
  }
  /* ubuntu-italic - latin_greek */
  @font-face {
	font-family: 'Ubuntu';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url('/Assets/fonts/ubuntu-v14-latin_greek-italic.eot'); /* IE9 Compat Modes */
	src: local('Ubuntu Italic'), local('Ubuntu-Italic'),
		 url('/Assets/fonts/ubuntu-v14-latin_greek-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-italic.woff') format('woff'), /* Modern Browsers */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
  }
  /* ubuntu-500 - latin_greek */
  @font-face {
	font-family: 'Ubuntu';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('/Assets/fonts/ubuntu-v14-latin_greek-500.eot'); /* IE9 Compat Modes */
	src: local('Ubuntu Medium'), local('Ubuntu-Medium'),
		 url('/Assets/fonts/ubuntu-v14-latin_greek-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-500.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-500.woff') format('woff'), /* Modern Browsers */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-500.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-500.svg#Ubuntu') format('svg'); /* Legacy iOS */
  }
  /* ubuntu-700 - latin_greek */
  @font-face {
	font-family: 'Ubuntu';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('/Assets/fonts/ubuntu-v14-latin_greek-700.eot'); /* IE9 Compat Modes */
	src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
		 url('/Assets/fonts/ubuntu-v14-latin_greek-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-700.woff') format('woff'), /* Modern Browsers */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/Assets/fonts/ubuntu-v14-latin_greek-700.svg#Ubuntu') format('svg'); /* Legacy iOS */
  }