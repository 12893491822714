.order{
    &__summary{
        span{
            font-weight:bold;
            color:$success;
        }
    }

    &__details{
        h4{
            font-size:$font-size-lg;
            font-weight:$font-weight-bold;
            color:$info;
        }

        span{
            font-weight:bold;
        }
    }

    &__totals{
        text-align:right;

        span{
            font-weight:bold;
            color:$success;
        }
    }

    &-history{
        &__item{
            padding:2rem;
            background-color:$secondary;
            border-radius:4px;

            > div > span{
                font-weight:700;
            }
        
            .btn{
                margin-top:1rem;
            }

            &:hover{
                background-color:darken($secondary, 1%);
            }
        }
    }
}