.cart{

    margin-top:3rem;

    &--popup{
        min-width:20rem;
        margin-top:0;
    }

    &__items{
        background:$secondary;
        padding:2rem;
        margin-bottom:2rem;

        @include media-breakpoint-only(xs){
            padding:1rem;
        }
    }

    &__overview{
        background:$secondary;
        padding:2rem;

        @include media-breakpoint-only(xs){
            padding:1rem;
        }
    }

    &__title{
        font-size:$font-size-lg;
        margin:0 0 1.5rem;
        font-weight:500;
    }

    &__total{
        padding:0.3rem 0rem 0.3rem;
        display:flex;
        justify-content: space-between;
        align-items: center;
        font-size:$font-size-lg;

        .value{
            font-weight:$font-weight-bold;
            color:$success;
        }
    }

    &__content{
        background-color:$white;
        padding:1rem 1.5rem;
        border:1px lighten($primary, 60%) solid;
        margin:1rem 0rem 0rem;

    }

    &__actions{
        margin-top:1rem;

    }

    &__action{
        &--checkout{
            margin:0.5rem 0rem 1.5rem;
        }
    }

}

.cart-item{
    background-color:$white;
    border:1px lighten($primary, 60%) solid;
    margin:1rem 0rem;
    padding:1rem 1.5rem;

    &:last-child{
        margin-bottom: 0;
    }

    &__photo{
        text-align:center;     
        
        @include media-breakpoint-only(xs){
            img{
                max-width:5rem;
            }
        }

    }

    &__top{
        padding-bottom:1rem;
        display:flex;
        justify-content: space-between;
        align-items:baseline;
    }

    &__bottom{
        display:flex;
        justify-content: space-between;
        flex-direction: row;
        align-items:center;
        padding-top:1rem;
        border-top:1px lighten($primary, 50%) solid;
    }

    &__name{
        padding:1rem 0rem 0rem;

        a{
            text-decoration:none;
            color:$primary;
            font-weight: $font-weight-bold;
            font-size: $font-size-lg;
            line-height:1.2;
            display:block;

        }
    }

    &__promo{
        color:$info;
        font-weight: $font-weight-bold;
    }

    &__code{
        padding-top:1rem;
        color:$primary;

        span{
            font-weight:bold;
        }
    }

    &__delete a{
        font-size:1.2rem;
        color:$info;
        margin-left:1rem;

        &:hover{
            color:$primary;
        }
    }

    &__price{
        color:$success;
        font-size:$font-size-lg;
        font-weight:bold;
        text-align:right;
    }

    &__quantity{
        display:flex;
        align-items:center;

        > div{
            display:flex;
            margin-left:1rem;
        }

        .quantity-handler{
            border:1px #ced4da solid;
            display:flex;
            align-items:center;
            padding:0 0.7rem;

            cursor:pointer;

            &:hover{
                background-color:$secondary;
            }
        }

        input{
            text-align:center;
            max-width:5rem;
            border-left:0;
            border-right:0;
        }
    }


    &--sm{
        padding:1rem 1rem;
        margin:1rem 0rem;
        font-size:$font-size-sm;

        .cart-item__name{
            padding:0.5rem 0rem 0rem;

            a{
                text-decoration:none;
                color:$primary;
                display:block;
                font-size:$font-size-base;
            }
        } 

        .cart-item__top{
            padding-bottom:1rem;
        }

        .cart-item__code{
            padding-top:0.5rem;
        }

    }

}

.shipping-msg{
    padding:0.5rem 0;
    margin:0.5rem 0;
    border-top:1px $gray-400 solid;
    border-bottom:1px $gray-400 solid;

    &__title{
        font-weight:$font-weight-bold;
        padding-bottom:0.3rem;
    }

    &__desc{
        font-style:italic;
        font-size:$font-size-sm;
        line-height:1.1;
    }

    span{
        color:$success;
        font-weight:$font-weight-bold;
    }
}