.carousel-wrapper{	
	position:relative;
	padding:1rem 1rem;

	.slider-arrows{
		display:flex;
		position:absolute;
        justify-content: space-between;
        top:50%;
        z-index:1;
        left:-0.5rem;
        right:-0.5rem;
        transform:translateY(-50%);
    }
    
    .tns-outer{
        z-index:2;
    }
	
	.slider-arrow{
		font-size:1rem;
		cursor:pointer;
		padding:0rem 0.5rem;
		transition:all 0.5s;
		outline:0;
	
		&:hover{
			color:$success;
		}
	}
}

.carousel{
    &__image{
        display:block;
    }
}