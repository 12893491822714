.block{
  padding:2rem 2rem;
  background:$white;

  &--sm{
    max-width:50rem;
  }

  &--md{
    max-width:80rem;
  }

  &__title{
    margin:0;
    padding:0;
    font-weight:700;
    font-size:$font-size-lg;
    margin-bottom:1rem;

    &--sm{
      font-size:$font-size-base;
    }
  }

}