.sidebar{

    &__box{
        margin-bottom:2rem;
    }

    &__title{
        margin:0;
        padding:0;
        padding:1rem 1rem;
        font-size:$font-size-lg;
        margin-bottom:0.5rem;
        background-color:$success;
        color:$white;
        text-align:center;
    }

    &__buttons{
        display:flex;
        justify-content:space-between;
        align-items:center;

        .btn{
            margin-bottom:1rem;
        }
    }

    &__content{
        padding:0.5rem 1rem;
        background:$white;

        ul{
            padding:0;
            margin:0;
            list-style:none;
        }

        li a{
            display:block;
            padding:0.1rem 0;
            margin:0.1rem 0;
            transition: all 0.5s;
            text-decoration:none;
            color:$primary;

            &:hover{
                color:lighten($primary, 50%);
            }

        }
    }

}