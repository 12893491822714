.payment,
.shipping-option{
    margin:.5rem 0rem .5rem;
    
    button{
        display:flex;
        justify-content: space-between;
    }

    svg{
        font-size:1.2rem;
    }

    input{
        visibility:hidden;
    }

    &__btn.btn-success ~ &__desc{
        display:block !important;
    }

    &__desc{
        padding:1rem 2rem;
        position:relative;
        background-color:$white;
        border-radius:4px;
        margin-top:1rem;
        margin-bottom:2rem;

        &::before{
            content:'';
            display:block;
            width:0;
            height:0;
            position:absolute;
        
            border-top: 1rem solid transparent;
            border-bottom: 1rem solid $white;
            border-right:1rem solid transparent;
            border-left:1rem solid transparent;
            left:2rem;
        
            top:-2rem;
        }

    }
}