.my-select {
  border:0;
  padding:0;
  margin:0;
  display: inline-block;
  font-family:inherit;
  color: $primary;
  line-height: 1.3;
  padding: 0.5rem 1.6rem 0.5rem 0.5rem;
  max-width: 100%; 
  box-sizing: border-box;
  border: 1px solid $secondary;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $light;
  background-image: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"></path></svg>');
  background-repeat: no-repeat, repeat;
  background-position: right .7rem top 50%, 0 0;
  background-size: .7rem auto, 100%;
  cursor:pointer;
  outline: none;

  &::-ms-expand{
    display: none;
  }

  &:hover{
    color:$black;
  } 

  &:focus{
    color:$black;
    outline: none;
  }

  option{
    font-weight:normal;
  }
}


@media (min-width: 992px) {
  .animate {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-name: slideIn;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@include media-breakpoint-up(md){
  .collapse.dont-collapse-md{
    display: block;
    height: auto !important;
    visibility: visible;
  }
}


@include media-breakpoint-up(sm){
  .collapse.dont-collapse-sm{
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

.blue{ color:$blue !important; }
.indigo{ color:$indigo !important; }
.purple{ color:$purple !important; }
.pink{ color:$pink !important; }
.red{ color:$red !important; }
.orange{ color:$orange !important; }
.yellow{ color:$yellow !important; }
.green{ color:$green !important; }
.teal{ color:$teal !important; }
.cyan{ color:$cyan !important; }