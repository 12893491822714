.index-heading{
    margin:1rem 0 0.6rem;
    text-align:center;

    &__icon{
        margin-bottom:0.5rem;
    }

    &__title{
        color: $primary;
        font-size: 1.5rem;
        font-weight:300;
    }

}