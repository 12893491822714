.checkout-prompt{
    padding:0;

    @include media-breakpoint-up(lg){
        padding:1rem 3rem;
    }

    &__option{
        padding:1.5rem;
        background:$light;
        margin:1rem 0;

        @include media-breakpoint-up(lg){
            margin:1rem;
            padding:2rem;
        }

        @include media-breakpoint-up(xl){
            margin:2rem;
            padding:3rem;
        }

        &--signup{
            a{
                margin-top:2rem;
            }
        }
    }

    &__text{
        padding:0.5rem 0rem;

        ul{
            padding:1rem 0rem 1rem 0rem;
            list-style:none
        }

        li{
            padding:0.3rem 0 0.3rem 1rem;
        
            &:before{
                content:"";
                display:inline-block;
                width:0.7rem;
                height:0.7rem;
                border-radius:50%;
                background-color:$success;
                margin-right:0.5rem;
            }
        }
    }

    &__or{
        border-bottom: 1px $primary solid;
        margin: 3rem 0;

        span{
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            background:$primary;
            display: block;
            margin: auto;
            margin-bottom: auto;
            text-align: center;
            line-height: 3rem;
            margin-bottom: -1.5rem;
            color:$white;
        }
    }
}