/**
 * @author Script47 (https://github.com/Script47/Toast)
 * @description Toast - A Bootstrap 4.2+ jQuery plugin for the toast component
 * @version 1.1.0
 **/
.toast-container {
    position: fixed;
    z-index: 1055;
    margin: 5px;
}

.top-right {
    top: 0;
    right: 0;
}

.top-left {
    top: 0;
    left: 0;
}

.top-center {
    transform: translateX(-50%);
    top: 0;
    left: 50%;
}

.bottom-right {
    right: 0;
    bottom: 0;
}

.bottom-left {
    left: 0;
    bottom: 0;
}

.bottom-center {
    transform: translateX(-50%);
    bottom: 0;
    left: 50%;
}

.toast-container > .toast {
    min-width: 150px;
    background: transparent;
    border: none;
}

.toast-container > .toast > .toast-header {
    border: none;
}

.toast-container > .toast > .toast-header strong {
    padding-right: 20px;
}

.toast-container > .toast > .toast-body {
    background: white;
}
