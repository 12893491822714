.banners{
	&--slider{
		position:relative;

		.banner{
			position:relative;
			overflow:hidden;

			&__image{
				img{
					width:100%;
				}
			}

			.container{
				position:absolute;
				top:50%;
				left:0px;
				right:0px;
				z-index:10;
				display:flex;
				justify-content: center;

				@include media-breakpoint-up(md){
					justify-content: flex-end;
				}
			}

			&__content{
				padding:1.7rem;
				background:rgba($primary, 1);
				text-align:center;
				max-width:26.25rem;
				transition: all 0.5s;
				transform:translateY(-50%);
				line-height: 2.5;

				@include media-breakpoint-up(md){
					padding:2.5rem;
					transform:translateY(-40%);
					text-align:left;

					&:after{
						content:"";
						position:absolute;
						background:rgba($primary, 1);
						top:0;
						bottom:0;
						left:100%;
						width:100rem;						
						transition: all 0.5s;
					}
				}


				
				&:hover,
				&:hover:after{
					background:rgba($primary, 1);
				}
				
			}

			&__title{
				color:$success;
				font-weight:500;
				font-size:1rem;

				@include media-breakpoint-up(md){
					font-size:1.835rem;
				}
			}

			&__description{
				padding:1rem 0;
				color:$white;
				font-size: 1.125rem;
				line-height: 1.3;
			}

			.btn-success{				
				color: $white;

				&:hover {
					background: $black;
				}
			}
		}
	}


	&--fullwidth{
		.banner{
			position:relative;

			.container{
				position:absolute;
				top:50%;
				z-index:10;
				display:flex;
				justify-content: center;

				@include media-breakpoint-up(md){
					justify-content: flex-end;
				}
			}

			&__image img{
				min-height:20rem;
				object-fit: cover;
				transition: all 0.5s;
				width:100%;
			}

			&__content{
				padding:1.5rem;
				background:rgba($primary, 0.7);
				text-align:center;
				width:25rem;
				transition: all 0.5s;
				transform:translateY(-50%);
				line-height: 0.7 !important;

				@include media-breakpoint-up(md){
					padding:2.5rem;
					width:30rem;
					transform:translateY(-40%);

					&:after{
						content:"";
						position:relative;
						top:0;
						bottom:0;
						left:100%;
						background:rgba($primary, 0.9);
						transition: all 0.5s;
					}
				}

				&:hover,
				&:hover:after{
					background:rgba($primary, 1);
				}

			}

			&__title{
				color:$white;
				font-weight:500;
				font-size:1rem;

				@include media-breakpoint-up(md){
					font-size:1.5rem;
				}
			}

			&__description{
				padding:1rem 0;
				color:$white;
			}

			&__button{
				margin-top:1rem;
				transition:all 0.5s;
				color:$white;
	
				&:hover {
					background: $black;
				}				
			}

			&:hover{

				.banner__image img{
					filter: blur(3px);
				}

				.banner__title{
					transform:translateY(0);
					opacity:1;
				}

				.banner__description{
					transform:translateY(0);
					opacity:1;
				}

				.banner__button{
					opacity:1;
					transform:translateY(0);
				}

			}
		}
	}

	&--image-text{
		.banner{
			position:relative !important;
			margin-bottom:0rem;
			overflow:hidden;
			border: 1px solid #eee;

			&__image{
				img{
					width:100%;
					transition: all 0.5s;
				}
			}

			&__content{
				
				padding:1rem;
				position:absolute;
				bottom:.5em;
				right:0rem;
				text-align:center;
				transition: all 0.5s;
			}

			&__title{
				color:$success;
				font-size:1.3rem;
				line-height:1.3;
				display:block;
				text-decoration:underline;

				&:hover{
					text-decoration:none;
				}
			}

			&__description{
				color:$black;
				font-size:$font-size-sm;
				display:block;
				text-decoration:underline;

				&:hover{
					text-decoration:none;
				}
			}

			&:hover{

				.banner__content{
					/*background:rgba($primary, 1);	*/
				}
				
			}

			&.hide .banner__content{
				display:none;
			}
			
			&.contrast .banner__image:hover img{
				filter: contrast(120%);
			}

			&.zoom .banner__image:hover img{
				transform: scale(1.1);
			}

		}
	}


	&--image-btn{
		.banner{
			position:relative !important;
			margin-bottom:0rem;

			&__image{
				img{
					width:100%;
				}
			}

			&__content{
				padding:0.5rem 0rem;
			}

			&__title{
				color:$primary;
				font-size:$font-size-lg;
				display:block;
			}

			&__description{
				color:$success;
				font-size:$font-size-sm;
				display:block;
			}

			&__button{
				color:$secondary;
				font-size:1.7rem;
				display:block;
				text-align:center;
				padding:0 2rem;

				&:hover{
					text-decoration:none;
				}
			}

		}
	}


	&--default{
		.banner{
			position:relative;
			margin-bottom:0rem;

			&__image{
				img{
					width:100%;
				}
			}

		}
	}
}


.tns-outer{
    position:relative;
}

.tns-nav{
    text-align:right;
    position: absolute;
    left: 0;
    right: 21rem;
    bottom: 1rem;
    z-index:1;

    button {
        width:1rem;
        height:1rem;
        border-radius:50%;
        border:0;
        background:$black;
        margin:0.2rem;

        &.tns-nav-active{
            background:$success;
        }
    }
}

.tns-controls{

    button{
        border:0;
        background:none;
        color:$white;
        font-size:2rem;
        margin:1rem;
    }
}