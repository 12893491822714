.article{

    &--default{
        background-color:$light;
        margin-bottom:1rem !important;
    
        .article__image{
            border:1px $light solid;
        }
    
        .article__title{
            font-weight:$font-weight-bold;
        }

        .article__details{
            padding:1rem 1rem;
            text-align:center;
        }
    
        .article__abstract{
            padding:1rem 0rem;
            font-size:$font-size-sm;
            color:$primary;
        } 
    }


}



.article-view{

    &__photo{
        background:$secondary;
        text-align:center;
        margin-bottom:2rem;
    }

    &__share{
        display:flex;
        align-items:center;
        margin-top:1rem;

        .caption{
            margin-right:0.4rem;
        }
    }

}
